<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CompanyRegistrationPage",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.SETTINGS"), route: "/settings" },
      { title: this.$t("SIDEBAR.COMPREGISTRATION") },
    ]);
  },
};
</script>
